import { prng } from "./prng"

interface Tile {
	el: HTMLElement
	top: number
	left: number
	letter: string
}

type Group = Tile[]
type Row = Group[]

const TILE_SIZE = 50
const TILE_SPACING = 60
let defaultZoom = 1
let moving = false
let selecting = false
let moved = false
let selection: Group = []
let selectionBox = {x1: null, y1: null, x2: null, y2: null, el: createEl('selectionBox', -1, -1)}
let allTiles: Group = []
let elMap = new Map()
let traySize = 0
let mouseEventsRemoved = false
let currentPrng: () => number = null
let r = 0
let logs = []

let currentWidth
let currentHeight
let currentZoom = 1
let showCaption = false
let showSeed = false
let formatted = false
let formatLeft = false

initListeners()

let {frequencies, charTotal} = initFrequencies()

deal()

function initListeners() {
	let tapStart = 0
	let firstTap = 0
	let lastTouchEvent = 0
	let tileSelected = false

	document.documentElement.addEventListener('mousedown', handleStart)
	document.documentElement.addEventListener('mousemove', handleMove)
	document.documentElement.addEventListener('mouseup', handleEnd)

	document.documentElement.addEventListener('touchstart', handleStart, {passive: false})
	document.documentElement.addEventListener('touchmove', handleMove, {passive: false})
	document.documentElement.addEventListener('touchend', handleEnd)

	document.querySelector('button#draw')?.addEventListener('click', deal)
	document.querySelector('button#format')?.addEventListener('click', format)
	document.querySelector('button#collapse')?.addEventListener('click', collapse)
	document.querySelector('button#shuffle')?.addEventListener('click', shuffle)
	document.querySelector('button#showCaption')?.addEventListener('click', toggleCaption)
	document.querySelector('button#showSeed')?.addEventListener('click', toggleSeed)
	// document.querySelector('button#say')?.addEventListener('click', say)
	document.querySelector('input#seed')?.addEventListener('change', updateSeed)

	// document.querySelector('button#draw')?.addEventListener('touch', deal)
	// document.querySelector('button#format')?.addEventListener('touch', format)
	// document.querySelector('button#shuffle')?.addEventListener('touch', shuffle)

	currentWidth = document.documentElement.clientWidth
	currentHeight = document.documentElement.clientHeight
	if (currentWidth < 600) {
		defaultZoom = 4/5
		currentZoom = defaultZoom
		setZoomClass()
	}
	let resizer = null

	window.addEventListener('resize', e => {
		if (resizer) clearInterval(resizer)
		resizer = setTimeout(handleResize, 100)
	})

	function handleStart(e: MouseEvent | TouchEvent) {
		let now = Date.now()
		if (e.type == 'touchstart') {
			lastTouchEvent = now
		}
		else if (e.type == 'mousedown') {
			if (now - lastTouchEvent < 1000) {
				// ignore mouse event when there's a recent touch event
				return
			}
		}

		// if (e.type )
		// log(e)
		log(e.target)
		let el = e.target as Element
		if (el.nodeName == 'BUTTON' || el.nodeName == 'INPUT' || el.nodeName == 'FIGCAPTION' || el.className == 'buttons-inner') {
			return
		}

		tapStart = now

		// cancel the mousedown event
		// if (e.type == 'touchstart' && !mouseEventsRemoved) {
		// 	console.log('removing mouse handlers')
		// 	document.documentElement.removeEventListener('mousedown', handleStart)
		// 	document.documentElement.removeEventListener('mousemove', handleMove)
		// 	document.documentElement.removeEventListener('mouseup', handleEnd)
		// 	mouseEventsRemoved = true
		// }
		// if (e.targetTouches.length > 1) {
		// 	return
		// }
		if (e.type == 'touchstart') {
			// e.preventDefault()
		}
		let move = (e instanceof TouchEvent) ? e.touches[0] : e
		let x = move.clientX, y = move.clientY
		prevX = x
		prevY = y

		let tileEl
		if (el.classList.contains('tile')) {
			tileEl = el
		}
		else if (el.classList.contains('t')) {
			tileEl = el
			while (!tileEl.classList.contains('tile'))
				tileEl = tileEl.parentNode
		}
		if (tileEl) {
			tileSelected = true
			let tile = elMap.get(tileEl)
			if (!(selection.length && selection.includes(tile))) {
				if (selection.length) selection.map(tile => tile.el.classList.remove('selected'))
				selection = [tile]
				// setSelection([tile])
			}
			moving = true
		}
		else {
			tileSelected = false
			selectionBox.x1 = x
			selectionBox.y1 = y
			selectionBox.x2 = x
			selectionBox.y2 = y
			selecting = true

			if (tapStart - firstTap > 0 && tapStart - firstTap < 500) {
				console.log('potential double tap detected')
			}
		}
	}

	let prevX, prevY
	function handleMove(e) {
		let now = Date.now()
		if (e.type == 'touchmove') {
			lastTouchEvent = now
		}
		else if (e.type == 'mousemove') {
			if (now - lastTouchEvent < 1000) {
				// ignore mouse event when there's a recent touch event
				return
			}
		}

		let el = e.target as Element
		if (el.nodeName == 'BUTTON' || el.className == 'buttons') {
			return
		}

		log(e)

		// allow pinch/zoom
		if (e.type == 'touchmove' && e.targetTouches.length == 2) {
			return
		}
		else {
			// 	//prevent scrolling
			e.preventDefault()
		}

		tapStart = 0
		firstTap = 0
		let move
		if (moving || selecting) {
			move = e.touches && e.touches[0] || e
		}
		if (moving) {
			let dx = 0,
				dy = 0

			if ('movementX' in move) {
				dx = move.movementX
				dy = move.movementY
			}
			else {
				dx = move.clientX - prevX
				dy = move.clientY - prevY
				prevX = move.clientX
				prevY = move.clientY
			}
			moveSelection(dx, dy)
			moved = true
		}
		else if (selecting) {
			selectionBox.x2 = move.clientX
			selectionBox.y2 = move.clientY
			drawSelectionBox(true)
		}
	}

	function handleEnd(e) {
		let now = Date.now()
		if (e.type == 'touchend') {
			lastTouchEvent = now
		}
		else if (e.type == 'mouseup') {
			if (now - lastTouchEvent < 1000) {
				// ignore mouse event when there's a recent touch event
				return
			}
		}

		log(e)
		if (e.target.nodeName == 'BUTTON' || e.target.nodeName == 'FIGCAPTION') {
			return
		}

		let t = now
		if (tapStart && t - tapStart < 200) {
			if (tapStart - firstTap > 0 && tapStart - firstTap < 400) {
				console.log('double tap detected')

				if (!tileSelected) {
					toggleZoom()
				}
			}
			else {
				console.log('single tap detected')
				firstTap = t
				// lastTap = Date.now()
				if (selection.length == 1) {
					console.log('1 selected; selecting a word')
					selectWord(selection[0])
				}
				else if(e.target.nodeName !== 'BUTTON' && !moved) {
					console.log('(tap) clearing selection')
					clearSelection()
				}
			}
		}
		else if (selecting) {
			setSelection(findIntersection(allTiles, selectionBox))
			drawSelectionBox(false)
			console.log('setting selection', selection)

		}
		else {
			console.log('clearing selection')
			clearSelection()
		}

		if (moved) {
			tidy()
			formatted = false
		}

		moving = false
		selecting = false
		moved = false
	}

	function handleResize() {
		let dx = document.documentElement.clientWidth - currentWidth
		// let dy = document.documentElement.clientHeight - currentHeight

		currentWidth = document.documentElement.clientWidth
		currentHeight = document.documentElement.clientHeight

		allTiles.map(tile => {
			tile.left += dx / 2
			// tile.top += dy / 2
			moveTile(tile)
		})
	}
}

function clearSelection() {
	setSelection([])
}

function log(e, ...args) {
	return
	console.log(e, ...args)
	if (e instanceof TouchEvent || e instanceof MouseEvent) {
		logs.push([e.type, (e as TouchEvent)?.targetTouches?.length, ...args].join(' '))
	}
	else if (e instanceof HTMLElement) {
		logs.push([e.nodeName + (e.id ? '#' + e.id : '') + (e.className ? '.' + e.className : '')])
	}
	else {
		logs.push([e, ...args].join(' '))
	}
	logs = logs.slice(-20)
	document.querySelector('#log').innerHTML = logs.join('<br>')
}

function setSelection(nextSelection) {
	selection = nextSelection
	allTiles.map(tile => {
		if (selection.includes(tile)) {
			tile.el.classList.add('selected')
		}
		else {
			tile.el.classList.remove('selected')
		}
	})
}

function createEl(className, left, top, text?) {
	let el = document.createElement('div')
	if (text) el.innerText = text
	el.style.top = top + 'px'
	el.style.left = left + 'px'
	el.className = className
	document.body.appendChild(el)
	return el
}

// document.documentElement.addEventListener('touchstart', (e) => {
//   console.log('touchstart', e)
// })

// document.documentElement.addEventListener('touchmove', (e) => {
//   moveSelection(e)
// })

// document.documentElement.addEventListener('touchend', (e) => {
//   processEnd(e)
// })

function moveSelection(dx, dy) {
	// console.log('moveSelection', dx, dy)
	if (selection.length) {
		selection.map(tile => {
			tile.top += dy
			tile.left += dx

			moveTile(tile)
		})
	}
}


function snapX(n: number) {
	// const gridSize = TILE_SPACING * currentZoom
	const gridSize = 15 * currentZoom
	const gapSize = (TILE_SPACING - TILE_SIZE) * currentZoom / 2
	let offs = (currentWidth / 2 + gapSize) % gridSize
	return Math.round((n - offs) / gridSize) * gridSize + offs
	// return Math.round((n - offs) / gridSize) * gridSize + offs
}

function snapY(n: number) {
	// const gridSize = TILE_SPACING * currentZoom
	const gridSize = 15 * currentZoom
	const gapSize = (TILE_SPACING - TILE_SIZE) * currentZoom / 2
	return Math.round((n - gapSize) / gridSize) * gridSize + gapSize
	// const gridSize = TILE_SPACING * currentZoom;
	// let offs = (currentWidth / 2 + gapSize) % gridSize
	// return Math.round((n - offs) / gridSize) * gridSize + offs
}

function moveTile(tile, doSnap = false) {
	if (doSnap) {
		tile.top = snapY(tile.top)
		tile.left = snapX(tile.left)
	}
	tile.el.style.top = tile.top + 'px'
	tile.el.style.left = tile.left + 'px'
}

function removeAll() {
	allTiles.forEach(tile => {
		tile.el.remove()
	})
	allTiles = []
	elMap.clear()
}

function changeColors() {
	let hue = Math.floor(Math.random() * 360)
	document.body.style.background = `hsl(${hue}, 21%, 53%)`
	;(document.querySelector('.buttons') as HTMLElement).style.background = `hsl(${hue}, 21%, 53%)`
	;(document.querySelector('#tray') as HTMLElement).style.background = `hsl(${hue}, 10%, 60%)`
}

function deal() {
	removeAll()

	changeColors()

	toggleCaption(false)

	let tileCount = Math.floor(15 + random() * 11)
	let letters = randomWord(tileCount).split('')
	// letters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'.split('')
	// it could have changed by 1 if there was a 'qu' in there
	tileCount = letters.length
	// console.log(tileCount)
	// tileCount = 17
	// let tilesPerRow = Math.floor((currentWidth - 20) / TILE_SPACING)
	// let rowWidth = tilesPerRow * TILE_SPACING - 10

	let tilesPerRow = tileCount
	let tileSpacing = TILE_SPACING * currentZoom
	let rowWidth, rows = 0
	do {
		++rows
		tilesPerRow = Math.ceil(tileCount / rows)
		rowWidth = tilesPerRow * tileSpacing
	} while (rowWidth > currentWidth)

	let startLeft = (currentWidth - rowWidth) / 2
	let y = tileSpacing * 2
	let rowI = -1

	allTiles = letters
	.map(letter => {
		rowI++
		if (rowI >= tilesPerRow) {
			rowI = 0
			y += tileSpacing
		}

		let left = startLeft + rowI * tileSpacing,
			top = y
		let el = createEl('tile', left, top, letter)
		// terrible CSS hack for Jost* font
		if (letter == 'J') {
			el.classList.add('j-tile')
			el.innerHTML = `<span class="t"><span class="t">J</span></span>`
		}
		let tile: Tile = { letter, left, top, el }

		elMap.set(el, tile)

		return tile
	})

	setTraySize()
	tidy()
}

function tidy() {
	console.log('tidy')
	let groups = parse()
	let topRow = groups.tray

	groups.main.forEach(group => {
		let x, y
		group.forEach((tile, i) => {
			if (i == 0) {
				moveTile(tile, true)

				x = tile.left
				y = tile.top
			}
			else {
				tile.left = x + (i * TILE_SPACING * currentZoom)
				tile.top = y
				moveTile(tile)
			}
		})
	})

	if (topRow.length) {
		const tileSpacing = TILE_SPACING * currentZoom
		let x = snapX((currentWidth - (rowLength(topRow) * tileSpacing)) / 2)
		let top = (tileSpacing - TILE_SIZE * currentZoom) / 2
		// console.log('topRow', x, top)
		topRow.forEach(group => {
			group.forEach(tile => {
				tile.left = x
				tile.top = top

				x += tileSpacing

				moveTile(tile)
			})

			x += tileSpacing
		})
	}
}

// separate into words for tidying, formatting, or other processing of the string
function parse(noSelection = false) {
	let remainingTiles = selection.length && !noSelection ? selection : allTiles.slice()

	let groups: Group[] = []

	// top-to-bottom, left-to-right sort (reading order)
	let sorted = remainingTiles.sort((tileA, tileB) => (tileA.left / 10 + tileA.top)  - (tileB.left / 10 + tileB.top))
	// console.log('sorted', sorted)

	let nextTile: Tile = null
	// prevent infinite loops if there are bugs while developing
	let rounds = 100
	let parsed = ''
	let nextGroup: Group = []

	while (sorted.length) {
		// console.log('sorted.length', sorted.length)
		if (!nextTile) {
			if (parsed.length) parsed += ' '
			nextTile = sorted.shift()

			if (nextTile) {
				nextGroup = [nextTile]
				groups.push(nextGroup)
			}
			parsed += nextTile.letter
		}
		else {
			let result = nextNeighbor(nextTile, sorted)
			if (result) {
				let prevTile = nextTile
				nextTile = result[0]
				sorted = result[1]
				parsed += nextTile.letter

				nextGroup.push(nextTile)

				moveTile(nextTile)
			}
			else {
				nextTile = null
			}
		}
		rounds--
		if (rounds == 0) {
			// console.log('stuck in a loop')
			break
		}
	}

	// if (inUseOnly) {
	let h = TILE_SIZE * currentZoom / 2
	let main: Group[] = [], tray: Group[] = []
	groups.forEach(group => {
		if (group[0].top + h > traySize) {
			main.push(group)
		}
		else {
			tray.push(group)
		}
	})
	// 	groups = groups.filter(group => group[0].top + h > traySize)
	// }

	console.log('parsed', main.map(group => group.map(tile => tile.letter).join('')).join(' '))

	return {main, tray}
}

function format() {
	let groups = parse(true).main
	let rows: Row[] = toRows(groups)

	const formatSpacing = 75 * currentZoom
	const tileSpacing = TILE_SPACING * currentZoom

	// if they click format twice, toggle left/center formatting
	if (formatted) {
		formatLeft = !formatLeft
	}

	let startTop = snapY((currentHeight - (rows.length * formatSpacing)) / 2)
	let maxRowLength =  0
	if (formatLeft) {
		maxRowLength = rows.reduce((max, row) => Math.max(max, rowLength(row)), 0)
	}

	let startLeft = 0
	if (formatLeft) {
		startLeft = snapX((currentWidth - (maxRowLength * tileSpacing)) / 2)
	}

	rows.forEach((row, r) => {
		let top = startTop + r * formatSpacing
		let x = startLeft
		if (!formatLeft) {
			x = snapX((currentWidth - (rowLength(row) * tileSpacing)) / 2)
		}

		row.forEach(group => {
			group.forEach(tile => {
				tile.left = x
				tile.top = top

				x += tileSpacing

				moveTile(tile)
			})

			x += tileSpacing
		})
	})

	formatted = true
}

function toRows(groups: Group[]) {
	let rows: Row[] = []
	let lastRow: Row = null
	groups.forEach(group => {
		if (lastRow && group[0].top == lastRow[0][0].top) {
			lastRow.push(group)
		}
		else {
			lastRow = [group]
			rows.push(lastRow)
		}
	})

	return rows
}

function rowLength(row: Group[]) {
	return row.reduce((tot, nextGroup) => tot + nextGroup.length + 1, 0) - 1
}

function collapse() {
	let groups = parse().main

	if (!groups.length) return

	//flatten to a single group
	let group = [].concat(...groups)
	let startLeft = Infinity, startTop = Infinity//, bottom = -Infinity

	group.forEach((tile, t) => {
		// find the left-most and top-most tiles
		startLeft = Math.min(startLeft, tile.left)
		startTop = Math.min(startTop, tile.top)
		// bottom = Math.min(bottom, tile.bottom)
	})
	group.forEach((tile, t) => {
		tile.left = startLeft + t * TILE_SPACING
		tile.top = startTop

		moveTile(tile)
	})
}

function shuffle() {
	let tiles = selection.length ? selection : allTiles.slice()
	let positions = tiles.map(tile => ({left: tile.left, top: tile.top}))
	shuffleFY(positions)

	for (let i = 0; i < tiles.length; i++) {
		tiles[i].left = positions[i].left
		tiles[i].top = positions[i].top
		moveTile(tiles[i])
	}
}

function updateSeed() {
	let seedValue = document.querySelector<HTMLInputElement>('#seed').value
	if (seedValue) {
		// console.log('update seed', seedValue)
		currentPrng = prng.fromString(seedValue.toUpperCase())
	}
	else {
		// console.log('remove prng')
		currentPrng = null
	}
	r = 0
	deal()
}

function say() {
	let groups = parse().main
	let sentence = groups.map(group => group.map(tile => tile.letter).join('')).join(' ')
	let ut = new SpeechSynthesisUtterance(sentence)
	ut.rate = .8
	speechSynthesis.speak(ut)
}

function initFrequencies() {
	let frequencies: {letter: string, frequency: number, percentile?: number}[] = [
		{ letter: 'E', frequency: 21912 },
		{ letter: 'T', frequency: 16587 },
		{ letter: 'A', frequency: 14810 },
		{ letter: 'O', frequency: 14003 },
		{ letter: 'I', frequency: 13318 },
		// too many Ns
		// { letter: 'N', frequency: 12666 },
		{ letter: 'S', frequency: 11450 },
		{ letter: 'R', frequency: 10977 },
		{ letter: 'H', frequency: 10795 },
		{ letter: 'N', frequency: 9666 },
		{ letter: 'D', frequency: 7874 },
		{ letter: 'L', frequency: 7253 },
		{ letter: 'U', frequency: 5041 },
		{ letter: 'C', frequency: 4943 },
		{ letter: 'M', frequency: 4761 },
		{ letter: 'F', frequency: 4200 },
		{ letter: 'Y', frequency: 3853 },
		{ letter: 'W', frequency: 3819 },
		{ letter: 'G', frequency: 3693 },
		{ letter: 'P', frequency: 3316 },
		{ letter: 'B', frequency: 2715 },
		{ letter: 'V', frequency: 2019 },
		{ letter: 'K', frequency: 1257 },
		{ letter: 'X', frequency: 415 }, // orig: 315
		{ letter: 'J', frequency: 388 }, // orig: 188
		{ letter: 'QU', frequency: 305 }, // orig: 205
		{ letter: 'Z', frequency: 228 } // orig: 128
	]

	let charTotal
	for (let f = 0, sum = 0; f < frequencies.length; f++) {
		let freq = frequencies[f]
		sum += freq.frequency
		freq.percentile = sum

		charTotal = sum
	}

	return {frequencies, charTotal}
}

function random() {
	let v = currentPrng ? currentPrng() : Math.random()
	r++
	// console.log(r, v)
	return v
}

function randomEnglishChar() {
	let rand = Math.floor(random() * charTotal)
	for (let f = 0; f < frequencies.length; f++) {
		let freq = frequencies[f]
		if (rand < freq.percentile) return freq.letter
	}
}

function randomWord(length) {
	let str = ''
	for (let i = 0; i < length; i++) {
		str += randomEnglishChar()
	}
	return str
}

function drawSelectionBox(selecting) {
	if (selecting && selectionBox.x1 != selectionBox.x2 && selectionBox.y1 != selectionBox.y2) {
		selectionBox.el.style.display = 'block'
		let left = Math.min(selectionBox.x1, selectionBox.x2)
		let top = Math.min(selectionBox.y1, selectionBox.y2)
		let width = Math.abs(selectionBox.x2 - selectionBox.x1)
		let height = Math.abs(selectionBox.y2 - selectionBox.y1)

		selectionBox.el.style.left = left + 'px'
		selectionBox.el.style.top = top + 'px'
		selectionBox.el.style.width = width + 'px'
		selectionBox.el.style.height = height + 'px'
	}
	else {
		selectionBox.el.style.display = 'none'
	}
}

function findIntersection(tiles, box) {
	if (box.x1 == box.x2 || box.y1 == box.y2) {
		return []
	}

	let s =  (TILE_SPACING - TILE_SIZE) / 2 * currentZoom
	let w = TILE_SIZE * currentZoom / 2 * currentZoom
	let left = Math.min(box.x1, box.x2) - s
	let top = Math.min(box.y1, box.y2) + s
	let right = Math.max(box.x1, box.x2) + s
	let bottom = Math.max(box.y1, box.y2) - s

	return tiles.filter(tile => {
		let x = tile.left + w
		let y = tile.top + w

		return (x > left && x < right && y > top && y < bottom)
	})
}

function selectWord(tile) {
	let wordTiles = [tile]
	let remainingTiles = allTiles.filter(otherTile => otherTile !== tile)

	// min and max are just the top/left of values of all the tiles in the word
	do {
		let result = nextNeighbor(tile, remainingTiles)

		if (result) {
			tile = result[0]
			remainingTiles = result[1]
			wordTiles.push(tile)
		}
		else {
			break
		}

	} while (true)

	if (wordTiles.length) {
		setSelection(wordTiles)
	}
}


function nextNeighbor(tile, candidates) {
	let box = {
		x1: tile.left,
		x2: tile.left + TILE_SIZE * currentZoom * 2,
		y1: tile.top,
		y2: tile.top + TILE_SIZE * currentZoom,
	}

	let found = findIntersection(candidates, box)
	if (found.length) {
		// if there are more than one in the box, do we only want the first one?
		// TODO: take the top/leftmost one. this will keep it linear, and hopefully
		// still get them all
		tile = found[0]
		candidates = candidates.filter(otherTile => otherTile !== tile)

		return [tile, candidates]
	}
	else {
		return null
	}
}

function shuffleFY(array) {
	let m = array.length, t, i

	while (m) {
		i = Math.floor(Math.random() * m--)
		t = array[m]
		array[m] = array[i]
		array[i] = t
	}

	return array
}

function toggleCaption(onOff) {
	if (typeof onOff == 'boolean') {
		showCaption = onOff
	}
	else {
		showCaption = !showCaption
	}

	const caption = document.getElementById('caption')

	if (!caption.innerText) {
		showCaption = true
		caption.innerText = 'click to change caption'
	}
	caption.style.display = showCaption ? 'block' : 'none'
	// if (showCaption && !caption.innerText) {
	// 	caption.innerText = 'click to change caption'
	// }
}

function toggleSeed(onOff) {
	if (typeof onOff == 'boolean') {
		showSeed = onOff
	}
	else {
		showSeed = !showSeed
	}

	const seed = document.getElementById('seed')

	seed.style.display = showSeed ? 'block' : 'none'
}

function toggleZoom() {
	// debugger
	let prevZoom = currentZoom
	currentZoom = currentZoom == defaultZoom ? 3/5 : defaultZoom
	let zoomFactor = currentZoom / prevZoom

	// let groups = parse()
	// let allTiles = groups.main

	let minLeft = Infinity, maxLeft = -Infinity, minTop = Infinity, maxTop = -Infinity
	let groups = parse()

	let mainTiles: Tile[] = [].concat([].concat(...groups.main))
	let trayTiles: Tile[] = [].concat([].concat(...groups.tray))

	mainTiles.forEach(tile => {
		tile.left *= zoomFactor
		tile.top *= zoomFactor

		minLeft = Math.min(tile.left, minLeft)
		maxLeft = Math.max(tile.left, maxLeft)
		minTop = Math.min(tile.top, minTop)
		maxTop = Math.max(tile.top, maxTop)
	})

	let cx = (maxLeft + TILE_SIZE * currentZoom + minLeft) / 2
	let cy = (maxTop + TILE_SIZE * currentZoom + minTop) / 2
	let xOffset = currentWidth / 2 - cx
	let yOffset = currentHeight / 2 - cy

	mainTiles.forEach(tile => {
		tile.left += xOffset
		tile.top += yOffset

		moveTile(tile, true)
	})


	trayTiles.forEach(tile => {
		tile.left *= zoomFactor
	})

	tidy()

	setZoomClass()
	setTraySize()
}

function setTraySize() {
	traySize = (TILE_SPACING) * currentZoom
	;(document.querySelector('#tray') as HTMLElement).style.height = traySize + 'px'
}

function setZoomClass() {
	document.body.className = ''
	if (currentZoom != 1) {
		document.body.classList.add('zoom-' + (currentZoom * 100))
	}
}
