// Special thanks to http://stackoverflow.com/questions/521295 and https://en.wikipedia.org/wiki/Random_number_generation.
// Note: this is fast and predictable, but not necessarily perfectly random or evenly distributed.  It should be fine
// for testing purposes.

// pseudorandom 32-bit int generator
const prng = {
	get: seed(0),
	seed: seed,
	fromString: fromString,
}

function seed(s: number) {
	let m_w  = 987654321 + s
	let m_z  = 123456789 - s
	let mask = 0xffffffff

	return function() {
		m_z = (36969 * (m_z & 65535) + (m_z >> 16)) & mask
		m_w = (18000 * (m_w & 65535) + (m_w >> 16)) & mask

		let result = ((m_z << 16) + m_w) & mask

		return (result + 2147483648) / 0xffffffff
		//result /= 4294967296
		//return result + 0.5
	}
}

function fromString(str: string) {
	return seed(hashCode(str))
}

function hashCode(str: string){
	let hash = 0, l = str.length, i, c
	if (l == 0) return hash
	for (i = 0; i < l; i++) {
		c = str.charCodeAt(i)
		hash = ((hash << 5) - hash) + c
		hash = hash & hash; // Convert to 32bit integer
	}
	return hash
}

export { prng }
